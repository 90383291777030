





















export default {
  data() {
    return {
      dark_mode: false,
      menu_items: [
        {
          label: this.translate('home'),
          value: 'home',
          name: 'home',
        },
        {
          label: this.translate('updates'),
          value: 'updates',
          name: 'updates',
        },
        {
          label: this.translate('checkYourAccount'),
          value: 'checkYourAccount',
          name: 'checkYourAccount',
        },
        {
          label: this.translate('dehashedCheck'),
          value: 'dehashedCheck',
          name: 'dehashedCheck',
        },
        {
          label: this.translate('experimentalCheck'),
          value: 'experimentalCheck',
          name: 'experimentalCheck',
        },
        {
          label: this.translate('passwordStrength'),
          value: 'passwordStrength',
          name: 'passwordStrength',
        },
        {
          label: this.translate('tracking'),
          value: 'tracking',
          name: 'tracking',
        },
        {
          label: this.translate('Blogs'),
          value: 'Blogs',
          name: 'Blogs',
        },
        {
          label: this.translate('myEntries'),
          value: 'myEntries',
          name: 'myEntries',
        },
        {
          label: this.translate('myEntriesDehashed'),
          value: 'myEntriesDehashed',
          name: 'myEntriesDehashed',
        },
        {
          label: this.translate('botLinks'),
          value: 'botLinks',
          name: 'botLinks',
        },
        {
          label: this.translate('bots'),
          value: 'bots',
          name: 'bots',
        },
        {
          label: this.translate('faq'),
          value: 'faq',
          name: 'faq',
        },
        {
          label: this.translate('contact'),
          value: 'contact',
          name: 'contact',
        },
        {
          label: this.translate('pricing'),
          value: 'pricing',
          name: 'pricing',
        },
        {
          label: this.translate('redeem'),
          value: 'redeem',
          name: 'redeem',
        },
        {
          label: this.translate('deleteMyEntries'),
          value: 'deleteMyEntries',
          name: 'deleteMyEntries',
        },
        {
          label: this.translate('language'),
          value: 'language',
          name: 'language',
        },
        {
          label: this.translate('ChromeExtension'),
          value: 'ChromeExtension',
          name: 'ChromeExtension',
        },
      ],
    };
  },
  methods: {
    changeDarkMode(val) {
      if (val) {
        localStorage.setItem('dark_mode', val);
      } else {
        localStorage.removeItem('dark_mode');
      }
      this.$store.commit('setDarkMode', val);
    },
    setDarkMode() {
      const dark_mode = localStorage.getItem('dark_mode');
      this.dark_mode = dark_mode;
    },
    reset() {
      this.dark_mode = false;
      this.menu_items.forEach((element) => {
        this.$set(element, 'value', element.name);
      });
      this.setMenuItems(this.menu_items);
      localStorage.removeItem('menu');
      this.changeDarkMode(false);
    },
    setMenuItems(data) {
      const menu_items = {};
      data.forEach((item) => {
        menu_items[item.name] = item.value !== null;
      });
      this.$store.commit('setMenuItems', menu_items);
    },
    saveSettings() {
      const m_items = this.menu_items;
      this.setMenuItems(m_items);
      localStorage.setItem('menu', JSON.stringify(m_items));
      this.changeDarkMode(this.dark_mode);
    },
    translate(prop) {
      return this.$vuetify.lang.t(`$vuetify.SideBar.${prop}`);
    },
    translateSettings(prop) {
      return this.$vuetify.lang.t(`$vuetify.Settings.${prop}`);
    },
  },
  beforeMount() {
    this.setDarkMode();
    try {
      const menu_storage = localStorage.getItem('menu');
      if (menu_storage) {
        const menu_items = JSON.parse(menu_storage);
        console.log('Menu Items', menu_items);
        this.menu_items.forEach((element, idx) => {
          const thisEl = menu_items.find((el) => el.name === element.value);
          if (thisEl && thisEl.value === null) {
            this.$set(element, 'value', null);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
};
